import { dasherize, parameterize, pluralize } from "inflected";

export const slugnderscore = (str: string) => parameterize(str, { separator: "_" }).replace(/[^a-zA-Z0-9_]/g, "_");

export const toSlug = (name: string) => dasherize(slugnderscore(name));

export const maybePluralize = (word: string, options: { locale?: string; count: number }) =>
  options.count == 1 ? word : pluralize(word, options.locale);

/** Joins an array of words using grammatically correct punctuation and words ("and") */
export const joinWords = (words: string[]) => {
  if (words.length === 0) return "";
  const localWords = [...words];
  const last = localWords.pop();
  const length = localWords.length;
  return length === 0 ? last : `${localWords.join(", ")}${length > 1 ? "," : ""} and ${last}`;
};

export const defaultSlugForUser = (user: { name?: string | null; email: string }, existingSlugs: string[] = []) => {
  const name = user.name ?? user.email.split("@")[0];
  const slugName = toSlug(name);
  const slugSegments = slugName.split("-");

  let slug;
  if (slugSegments[0].length > 3) {
    slug = slugSegments[0];
  } else {
    slug = slugName;
  }

  let slugSuffix = 2;
  let slugCandidate = `${slug}-dev`;

  while (existingSlugs.includes(slugCandidate)) {
    slugCandidate = `${slug}-dev-${slugSuffix}`;
    slugSuffix++;
  }

  return slugCandidate;
};
